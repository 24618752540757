<template>
    <div class="board-card" :style="containerStyle">
        <div class="author-box-and-more-options">
            <router-link
                :to="{ name: 'web-app-profile', params: { displayName: board.user.displayname } }"
                class="author"
            >
                <user-picture :picture="board.user.photo.url" />
                <user-nickname :user-nickname="board.user.displayname" />
            </router-link>
            <v-popover
                v-if="!isNotable && isBoardAuthor && !board.is_default"
                class="more-options"
            >
                <div class="tooltip-target b3">
                    <img src="@assets/img/icons/three-dots.svg" alt="More options">
                </div>
                <template slot="popover">
                    <div class="popover-options">
                        <div class="popover-option" @click="editBoard">
                            <span>Edit</span>
                            <img
                                src="@assets/img/icons/edit.svg"
                                class="edit"
                                alt="edit"
                            >
                        </div>
                        <div class="popover-option" @click="deleteBoard">
                            <span>Delete</span>
                            <img
                                src="@assets/img/icons/trash-can.svg"
                                class="delete"
                                alt="delete"
                            >
                        </div>
                    </div>
                </template>
            </v-popover>
        </div>
        <div class="card-container">
            <router-link :to="{ name: 'web-app-board-view', params: { displayName: board.user.displayname, id: board.id } }">
                <figure v-if="board.image_url">
                    <img
                        class="image img-fluid"
                        :src="board.image_url"
                        loading="lazy"
                        :alt="board.list_name"
                    >
                </figure>
            </router-link>
            <div class="card-body">
                <router-link :to="{ name: 'web-app-board-view', params: { displayName: board.user.displayname, id: board.id } }" class="text-truncate">
                    <img
                        v-if="!board.is_public"
                        class="is-private-board"
                        src="@assets/img/icons/private-board.svg"
                        alt="Private board"
                    >
                    <h3 class="text-truncate">
                        {{ board.list_name }}
                    </h3>
                </router-link>
                <follow-entity-button
                    v-if="!isBoardAuthor"
                    :entity-id="board.id"
                    :entity-name="board.list_name"
                    :is-following="Number(board.is_followed)"
                    entity="boards"
                    @toggled-follow="(is_followed) => $emit('toggled-follow', is_followed)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "BoardCard",
    components: {
        UserPicture: () => import(/* webpackChunkName: "user-picture" */ "@c/atoms/user-picture"),
        UserNickname: () => import(/* webpackChunkName: "user-name" */ "@c/atoms/user-nickname"),
        FollowEntityButton: () => import(/* webpackChunkName: "follow-entity-button" */ "@c/atoms/follow-entity-button")
    },
    props: {
        board: {
            type: Object,
            required: true
        },
        isNotable: {
            type: Boolean,
            default: false
        },
        containerStyle: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        ...mapState({
            userId: state => state.User.data.id
        }),
        isBoardAuthor() {
            return Boolean(Number(this.board.user.id) === Number(this.userId));
        }
    },
    methods: {
        editBoard() {
            this.$modal.show("create-board-modal", { isEdit: true, boardToEdit: { title: this.board.list_name, is_public: this.board.is_public, id: this.board.id } })
        },
        deleteBoard() {
            this.$modal.show("delete-board", { boardId : this.board.id, boardTitle: this.board.list_name });
        }
    }
}
</script>

<style lang="scss" scoped>
.board-card {
    width: 266px;

    .author-box-and-more-options {
        display: flex;
        justify-content: space-between;

        .author {
            display: flex;
            align-items: center;
            margin-bottom: 6px;
            color: white;
            text-decoration: none;

            /deep/ .user-picture {
                figure {
                    width: 27px;
                    height: 27px;
                }

                margin-right: 10px;
            }

            /deep/ .user-nickname {
                color: #D7D9DB;
            }
        }

        .more-options {
            cursor: pointer;
            height: 100%;
            height: 100%;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: auto;
        }
    }

    .card-container {
        background-color: #1C1C1E;
        border-radius: 10px;

        a {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-decoration: none;

            figure {
                margin-bottom: 0;
                position: relative;
                min-height: 199px;

                img {
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                    object-fit: cover;
                }

                &:after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    height: 40px;
                    width: 100%;
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, .4%));
                }
            }
        }

        .card-body {
            padding: 14px 12px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .is-private-board {
                width: 22px;
                height: 19px;
                margin-bottom: 10px;
            }

            h3 {
                color: white;
            }
        }
    }
}
</style>
